<template>
  <v-card>
    <v-data-table
        v-if="pSelectedTeam"
        class="nomination-table-display"
        :headers="headers"
        :items="displayNominations"
        :loading="currentPageLoading"
        :server-items-length="totalCount"
        @update:options="paginate($event)"
        :footer-props="footerProps"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            Nominations
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <div
              v-if="!pSelectedTeam.isTerminated"
              class="team-table-actions text-center d-flex align-center justify-space-around"
          >
            <div v-if="mayKickManager" class="text-center d-flex align-center justify-space-around">
              <v-btn
                  color="error"
                  class="kick-manager-btn mr-2"
                  @click="openKickManagerDialog()"
                  :disabled="currentPageLoading"
              >
                Kick manager
              </v-btn>
            </div>
            <div v-else-if="canNominateReplacementManager" class="text-center d-flex align-center justify-space-around">
              <v-btn
                  color="primary"
                  class="nominate-resigned-btn mr-2"
                  @click="openNominateReplacementManagerDialog()"
                  :disabled="currentPageLoading"
              >
                Nominate Replacement Manager
              </v-btn>
            </div>
            <v-tooltip v-else :disabled="disableManagerNominationTooltip" open-delay="500" top>
              <template v-slot:activator="{ on, attrs }">
                <div v-on="on" v-bind="attrs">
                  <v-btn
                      color="primary"
                      class="nomination-table-add-manager-btn mr-2"
                      :disabled="disableManagerNomination || currentPageLoading"
                      @click="openCreateNominationDialog(NOMINATION_TYPE_MANAGER)"
                  >
                    Nominate Manager
                  </v-btn>
                </div>
              </template>
              <span>{{ managerNominationTooltipText }}</span>
            </v-tooltip>
            <v-btn
                color="primary"
                class="nomination-table-add-member-btn"
                @click="openCreateNominationDialog(NOMINATION_TYPE_MEMBER)"
                :disabled="currentPageLoading"
            >
              Nominate Member
            </v-btn>
          </div>
        </v-toolbar>
      </template>

      <template v-slot:[`item.type`]="{ item }">
        {{ item.user.getUserTypeDisplayName() }}
      </template>

      <template v-slot:[`item.createdAt`]="{ item }">
        {{ item.createdAt ? moment(item.createdAt).format(dateTimeMomentFormat) : "" }}
      </template>

      <template v-slot:[`item.updatedAt`]="{ item }">
        {{ item.createdAt ? moment(item.updatedAt).format(dateTimeMomentFormat) : "" }}
      </template>

      <template v-slot:[`item.expiresAt`]="{ item }">
        {{ item.expiresAt ? moment(item.expiresAt).format(dateTimeMomentFormat) : "" }}
      </template>

      <template v-slot:[`item.nominationType`]="{ item }">
        {{ NOMINATION_TYPES_BY_VALUE[item.nominationType].text }}
      </template>

      <template v-slot:[`item.nominationApprovalState`]="{ item }">
        <NominationStateComponent :pNomination="item"/>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex">
<!--          <v-btn-->
<!--            class="nomination-permissions-btn"-->
<!--            x-small-->
<!--            :disabled="item.isAccepted"-->
<!--            @click="openEditNominationRolesDialog(item)"-->
<!--          >-->
<!--            Edit roles-->
<!--          </v-btn>-->
          <v-icon
              medium
              class="ml-2 nomination-table-delete-btn"
              color="red"
              @click="openDeleteNominationDialog(item)"
              :disabled="!checkIsDeletedNomination(item)"
          >mdi-delete</v-icon>
        </div>
      </template>
    </v-data-table>
    <NominationDialog
        @onReloadData="$emit('onReloadData')"
        :pSelectedTeam="pSelectedTeam"
        ref="editNominationDialog"
    />
    <ConfirmationDialog ref="confirmationDialog"/>
    <KickManagerDialog
        :pTeam="pSelectedTeam"
        :pNominations="displayNominations"
        ref="kickManagerDialog"
        @onUpdate="$emit('onReloadData')"
    />
    <NominateReplacementManagerDialog
        :pTeam="pSelectedTeam"
        ref="nominateReplacementManagerDialog"
        @onUpdate="$emit('onReloadData')"
    />
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { Vue } from "vue-property-decorator";
import {
  paginateTable,
  dateTimeMomentFormat,
  NOMINATION_TYPES_BY_VALUE,
  NOMINATION_TYPE_MEMBER,
  NOMINATION_TYPE_MANAGER,
  NOMINATION_STATE_ACCEPTED,
  NOMINATION_STATE_REMOVED,
  NOMINATION_STATE_DECLINED,
  TEAM_DRAFT_STATUS,
  TEAM_MANAGER_STATE_ACCEPTED,
  TEAM_MANAGER_STATE_INITIAL_INVITE,
  TEAM_MANAGER_STATE_NOMINATED,
  TEAM_MANAGER_STATE_FINAL_INVITE,
  NOMINATION_STATE_RESIGNED, logAndExtractMessage, dismissAction,
} from "@/utils/utils";

export default {
  name: "Nominations",

  components: {
    NominationStateComponent: () => import("../custom-components/NominationStateComponent.vue"),
    NominationDialog: () => import("../dialogs/NominationDialog.vue"),
    ConfirmationDialog: () => import("../dialogs/ConfirmationDialog.vue"),
    KickManagerDialog: () => import("./KickManagerDialog.vue"),
    NominateReplacementManagerDialog: () => import("./NominateReplacementManagerDialog.vue"),
  },

  props: {
    pSelectedTeam: Object,
  },

  data: () => ({
    NOMINATION_STATE_DECLINED,
    NOMINATION_STATE_REMOVED,
    NOMINATION_STATE_ACCEPTED,
    NOMINATION_TYPE_MANAGER,
    NOMINATION_TYPES_BY_VALUE,
    TEAM_MANAGER_STATE_ACCEPTED,
    TEAM_DRAFT_STATUS,
    NOMINATION_TYPE_MEMBER,
    TEAM_MANAGER_STATE_INITIAL_INVITE,
    TEAM_MANAGER_STATE_NOMINATED,
    TEAM_MANAGER_STATE_FINAL_INVITE,

    moment,
    dateTimeMomentFormat,

    localStorageTableSizeKey: "team.nominations.table.size",

    tableInited: false,

    footerProps: {
      "items-per-page-options": [25, 50, 100],
    },

    headers: [
      {
        text: "First Name",
        value: "user.firstName",
        sortable: false,
      },
      {
        text: "Last Name",
        value: "user.lastName",
        sortable: false,
      },
      {
        text: "Email",
        value: "user.email",
        sortable: false,
      },
      {
        text: "Nomination Type",
        value: "nominationType",
        sortable: false,
      },
      { text: "Approval State", value: "nominationApprovalState", sortable: false },
      { text: "Invitation Expires At", value: "expiresAt", sortable: false },
      { text: "Created at", value: "createdAt", sortable: false },
      { text: "Updated at", value: "updatedAt", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),

  computed: {
    ...mapState("userModule", ["user"]),
    ...mapState("nominationModule", [
      "currentPageLoading",
      "currentPage",
      "displayNominations",
      "totalCount",
    ]),

    managerNominationTooltipText() {
      if (
        this.pSelectedTeam.managerApprovalState === TEAM_MANAGER_STATE_NOMINATED.value
          || this.pSelectedTeam.managerApprovalState === TEAM_MANAGER_STATE_INITIAL_INVITE.value
          || this.pSelectedTeam.managerApprovalState === TEAM_MANAGER_STATE_FINAL_INVITE.value
      ) return "Pending manager nomination for this team already exists";

      if (this.pSelectedTeam.managerApprovalState === TEAM_MANAGER_STATE_ACCEPTED.value) return "Manager's Nomination already accepted";

      return "";
    },

    disableManagerNominationTooltip() {
      return !this.pSelectedTeam.managerApprovalState || this.disableManagerNomination;
    },

    disableManagerNomination() {
      return this.pSelectedTeam.managerApprovalState === TEAM_MANAGER_STATE_ACCEPTED.value
          || this.pSelectedTeam.managerApprovalState === TEAM_MANAGER_STATE_NOMINATED.value
          || this.pSelectedTeam.managerApprovalState === TEAM_MANAGER_STATE_INITIAL_INVITE.value
          || this.pSelectedTeam.managerApprovalState === TEAM_MANAGER_STATE_FINAL_INVITE.value;
    },

    mayKickManager() {
      return this.pSelectedTeam.managerViewerUser !== null && this.user.isSuperAdmin;
    },

    canNominateReplacementManager() {
      return this.pSelectedTeam.hasResignedNomination
          && this.pSelectedTeam.hasActiveTeamMembers
          && !this.disableManagerNomination;
    },
  },

  methods: {

    paginate(data) {
      data.itemsPerPage = paginateTable(
        data,
        this.localStorageTableSizeKey,
        this.tableInited,
      );
      const currentPage = {
        index: data.page - 1,
        size: data.itemsPerPage,
      };

      this.loadPage(currentPage);
      this.tableInited = true;
    },

    async loadPage(currentPage) {
      try {
        await this.$store.dispatch("nominationModule/loadPage", {
          ...currentPage,
          teamId: this.pSelectedTeam.id,
        });
      } catch (e) {
        Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
      }
    },

    openCreateNominationDialog(nominationType) {
      this.$refs.editNominationDialog.openDialog(nominationType, []);
    },

    openKickManagerDialog() {
      this.$refs.kickManagerDialog.openDialog();
    },

    openNominateReplacementManagerDialog() {
      this.$refs.nominateReplacementManagerDialog.openDialog();
    },

    openDeleteNominationDialog(nomination) {
      this.$refs.confirmationDialog.openDialog({
        header: "Delete confirmation",
        text: `Are you sure you want to delete nomination of "${nomination.user.firstName} ${nomination.user.lastName}"?`,
        submitText: "Delete",
        submitColor: "red",
        onSubmit: async () => {
          try {
            await this.$store.dispatch("nominationModule/deleteNomination", nomination);

            if (nomination.nominationType === NOMINATION_TYPE_MANAGER.value) {
              this.$emit("onReloadData");
            }
          } catch (e) {
            Vue.toasted.error(logAndExtractMessage(e), { ...dismissAction });
          }
        },
      });
    },

    checkIsDeletedNomination(nomination) {
      return nomination.nominationApprovalState !== NOMINATION_STATE_ACCEPTED.value
        && nomination.nominationApprovalState !== NOMINATION_STATE_DECLINED.value
        && nomination.nominationApprovalState !== NOMINATION_STATE_REMOVED.value
        && nomination.nominationApprovalState !== NOMINATION_STATE_RESIGNED.value;
    },
  },
};
</script>
